<template>
    <div class="modal-basic">
        <div class="title" v-html="options.title" />
        <ActivateForMale v-if="options.gender === 0" />
        <ActivateForFemale v-else />
        <div class="button">
            <BottomButton @click="onClick" :label="$translate('ACTIVATE_TODAYMEET')" :service="`activation`" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalActivationPage',
    props: ['options'],
    components: {
        ActivateForMale: () => import('@/routes/today-meeting/components/ActivateForMale'),
        ActivateForFemale: () => import('@/routes/today-meeting/components/ActivateForFemale'),
    },
    methods: {
        onClick() {
            this.$nativeBridge.postMessage({
                action: 'sendFirebaseEvent',
                value: {
                    category: 'todayMeetingEvent',
                    option: {
                        funnel: 'click_today_meeting_activate',
                    },
                },
            })
            localStorage.setItem('activation-check', true)
            this.$emit('close')
        },
    },
}
</script>

<style scoped lang="scss">
.modal-basic {
    width: 80vw;
    padding: 0;
}
.title {
    font-size: 22px;
    padding: 24px 16px 0 16px;
    color: black;
    margin-bottom: 20px;
    @include f-medium;
}
.button {
    padding: 0 16px 16px 16px;
}
</style>
